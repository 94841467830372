import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { WEBSITE } from "../constants/regex";
import { filterBySearchValue, filterBookmarked, getDataIntercom } from "./index";
import { QUESTION_TYPES } from "../constants/question";
import { actions as listingActions } from "../actions/listing";

export const useDocTitle = (title, keepOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(title);
  }, [t, title]);

  useEffect(() => {
    return () => {
      if (!keepOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, [keepOnUnmount]);
};

export const useNotification = (error, success) => {
  const [notes, setNotes] = useState({ success: null, error: null });

  useEffect(() => {
    if (Object.values(success).some(value => !!value)) {
      setNotes({ success: true });
      setTimeout(() => setNotes({ success: null }), 7000);
    }

    if (error) {
      setNotes({ error });
      setTimeout(() => setNotes({ error: null }), 7000);
    }
  }, [success, error]);

  return notes;
};

// question-answer page
export const useFiltrationQAndAPage = (activeFilters, searchValue, bookmarked, setQuestionsToRender) => {
  const { qa, answers, allQuestionAndAnswers } = useSelector(state => state.questions);

  useEffect(() => {
    if (!activeFilters.isFirstRender) {
      const { practices, categories, professions } = activeFilters;

      const filterByWebsiteUrl = item => categories.includes(item.website_url.replace(WEBSITE, "").split("/")[0]);
      const filterByPractices = ({ practice1, practice2, practice3 }) => {
        const toArrayPractices = [practice1, practice2, practice3];
        return practices.some(item => toArrayPractices.indexOf(item) >= 0);
      };
      const filterByProfessions = ({ profession_id: professionId }) => professions.includes(professionId);
      const filterByAllQuestions = item => filterByProfessions(item) && filterByWebsiteUrl(item);
      const filterByAllAnswers = item => filterByPractices(item) && filterByWebsiteUrl(item);

      let allAnswerAndQuestionsList = [];
      let questionsList = [];
      let answersList = [];

      if (practices.length && categories.length && professions.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByAllAnswers);
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByAllQuestions);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue).filter(filterByAllQuestions);
      } else if (practices.length && categories.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByAllAnswers);
        questionsList = filterBySearchValue(qa, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (professions.length && categories.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByAllQuestions);
        answersList = filterBySearchValue(answers, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (professions.length && practices.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByProfessions);
        answersList = filterBySearchValue(answers, searchValue).filter(filterByPractices);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (professions.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByProfessions);
        answersList = filterBySearchValue(answers, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (practices.length) {
        answersList = filterBySearchValue(answers, searchValue).filter(filterByPractices);
        questionsList = filterBySearchValue(qa, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else if (categories.length) {
        questionsList = filterBySearchValue(qa, searchValue).filter(filterByWebsiteUrl);
        answersList = filterBySearchValue(answers, searchValue).filter(filterByWebsiteUrl);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      } else {
        questionsList = filterBySearchValue(qa, searchValue);
        answersList = filterBySearchValue(answers, searchValue);
        allAnswerAndQuestionsList = filterBySearchValue(allQuestionAndAnswers, searchValue);
      }

      setQuestionsToRender({
        qa: filterBookmarked(bookmarked, questionsList),
        answers: filterBookmarked(bookmarked, answersList),
        all: filterBookmarked(bookmarked, allAnswerAndQuestionsList)
      });
    }
    // eslint-disable-next-line
  }, [activeFilters.practices, activeFilters.categories, activeFilters.professions, searchValue]);
};

// question-answer detail page
export const useUpdateQuestionsIds = (id, tab) => {
  const [prevQuestionId, setPrevQuestionId] = useState(null);
  const [nextQuestionId, setNextQuestionId] = useState(null);
  const { qa, answers } = useSelector(state => state?.questions);
  const answeredQuestions = qa.filter(item => Object.keys(answers).includes(item.question_id));
  const currentQuestionIdx = useMemo(() => qa.findIndex(item => item.question_id === id), [qa, id]);

  const getPrevNextQuestionsId = useCallback(
    questionsArr => {
      setPrevQuestionId(questionsArr?.[currentQuestionIdx - 1]?.question_id);
      setNextQuestionId(questionsArr?.[currentQuestionIdx + 1]?.question_id);
    },
    [currentQuestionIdx]
  );

  useEffect(() => {
    if (!tab || tab === QUESTION_TYPES.QUESTIONS) {
      getPrevNextQuestionsId(qa);
    }
    if (tab === QUESTION_TYPES.MY_ANSWER) {
      getPrevNextQuestionsId(answeredQuestions);
    }
  }, [qa, tab, id]);

  return { prevQuestionId, nextQuestionId };
};

export const useIntercom = ({ globalInfo, isLoggedIn, listings, profile }) => {
  useEffect(() => {
    if (window.Intercom) {
      if (profile?.user_id && globalInfo.name_f) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "lm1evs0k",
          user_id: profile.user_id,
          user_hash: profile.intercom_hash,
          name: `${globalInfo.name_f} ${globalInfo.name_l}`,
          email: globalInfo.email,
          created_at: globalInfo.added
        });
      }

      if (isLoggedIn === true) {
        getDataIntercom(listings);
      }

      window.Intercom("update");
    }
  }, [globalInfo, globalInfo.name_f, isLoggedIn, profile?.user_id]); // eslint-disable-line
};

export const useKeyDownEscape = callBack => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === "Escape") {
        callBack();
      }
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);
};

export const useOnclickOutside = (ref, handler, reset) => {
  useEffect(() => {
    const listener = event => {
      event.stopPropagation();
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", listener);

    return () => {
      if (!reset) handler();
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export const useKeyDownEnter = callBack => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === "Enter") {
        callBack();
      }
    },
    [callBack] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);
};

export const useChangePage = callBack => {
  const currentPage = useRef("");
  const location = useLocation();

  useEffect(() => {
    if (Boolean(currentPage.current) && currentPage.current !== location.pathname) {
      callBack();
    }

    if (currentPage.current !== location.pathname) {
      currentPage.current = location.pathname;
    }
  }, [location.pathname, currentPage]); // eslint-disable-line
};

export const useDebounce = (callBack, isSendHide) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      callBack(searchTerm);
      if (isSendHide) {
        if (searchTerm && isHide) {
          setIsHide(false);
        }

        if (!isHide && !searchTerm) {
          setIsHide(true);
        }
      }
    }, 1500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm]); // eslint-disable-line

  return { searchTerm, setSearchTerm, isHide };
};

export const useTrack = (eventNameTrack, parameters = {}) => {
  const { account } = useSelector(state => state);
  const { profile } = account;

  useEffect(() => {
    if (window.fbq && profile) {
      window.fbq("track", eventNameTrack, { em: profile.email, fn: profile.name_f, ln: profile.name_f, ...parameters });
    }
  }, [profile]); // eslint-disable-line
};

export const useValidationError = (errors, errorName) => {
  const dispatch = useDispatch();
  const { validationErrors } = useSelector(state => state.listing);
  const setValidationError = useCallback(error => dispatch(listingActions.setValidationError(error)), [dispatch]);

  useEffect(() => {
    if (errors !== "isRemove" && Object.values(errors).length && errors[errorName] !== validationErrors[errorName]) {
      setValidationError(errors);
    }

    if (errors === "isRemove" && validationErrors[errorName]) {
      const { [errorName]: value, ...rest } = validationErrors;

      setValidationError(rest);
    }
  }, [errors]); // eslint-disable-line
};
