import axios from "axios";
import queryString from "query-string";

import { questions } from "../constants/actionTypes";

export const actions = {
  getQuestionAnswer(questionId) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "get",
        url: "/members/json-api/qa/get-question",
        params: {
          question_id: questionId
        }
      })
        .then(({ data }) => {
          const { answers, question } = data;

          const sortedAnswers = Object.keys(answers)
            .reduce((total, current) => {
              return [...total, answers[current]];
            }, [])
            .sort((a, b) => new Date(b.added_tm) - new Date(a.added_tm));

          dispatch({
            type: questions.GET_CURRENT_QUESTION_ANSWER,
            payload: {
              answers: sortedAnswers,
              question
            }
          });
        })
        .catch(error => {
          console.error("getQuestionAnswer", error);
        })
        .finally(() => {
          dispatch(this.setLoading(false));
        });
    };
  },

  getQuestions(page) {
    return dispatch => {
      dispatch(this.setLoading(true));

      axios
        .all([
          axios({
            method: "get",
            responseType: "json",
            url: `members/json-api/qa/my-questions?page=${page || 0}`,
            headers: {
              "Content-Type": "application/json"
            }
          }),
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/qa/get-questions",
            headers: {
              "Content-Type": "application/json"
            }
          }),
          axios({ method: "get", responseType: "json", url: "/members/json-api/qa/categories-practices" })
        ])
        .then(
          axios.spread((questionsAll, myAnswers, categoriesPractices) => {
            const { questions: questionsList, ...pagination } = questionsAll.data;
            const { questions: myAnswerQuestions, filters: myAnswerFilter, ...myAnswerPagination } = myAnswers.data;

            dispatch({
              type: questions.GET_QA_SUCCESS,
              payload: {
                pagination,
                categories: categoriesPractices.data.categories,
                practices: categoriesPractices.data.practices,
                professions: questionsAll.data.filters?.professions,
                qa: questionsList,
                myAnswers: myAnswerQuestions,
                myAnswersFilter: myAnswerFilter,
                myAnswerPagination
              }
            });
          })
        );
    };
  },

  getMyQuestions({ page, dirId, practiceId, professionId, search, categoryId }) {
    return dispatch => {
      dispatch(this.setLoading(true));
      const searchValue = search ? { search } : {};

      const filterParams = queryString.stringify(
        { dir_id: dirId, practice_id: practiceId, profession_id: professionId, category_id: categoryId, page: page || 0, ...searchValue },
        { arrayFormat: "index" }
      );

      axios({
        method: "get",
        responseType: "json",
        url: `members/json-api/qa/my-questions?${filterParams}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(({ data }) => {
          const { questions: questionsList, ...pagination } = data;
          dispatch({
            type: questions.GET_MY_QUESTIONS,
            payload: {
              qa: questionsList,
              pagination
            }
          });
        })
        .catch(() => {
          dispatch({
            type: questions.GET_MY_QUESTIONS_FAIL
          });
        });
    };
  },

  getQuestionsList({ page, dirId, practiceId, professionId, search, categoryId }) {
    return dispatch => {
      dispatch(this.setLoading(true));
      const searchValue = search ? { search } : {};

      const filterParams = queryString.stringify(
        { dir_id: dirId, practice_id: practiceId, profession_id: professionId, category_id: categoryId, page: page || 0, ...searchValue },
        { arrayFormat: "index" }
      );

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api/qa/get-questions?${filterParams}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(data => {
          const { questions: myAnswerQuestions, filters: myAnswerFilter, ...myAnswerPagination } = data.data;

          dispatch({
            type: questions.GET_MY_ANSWER,
            payload: {
              myAnswers: myAnswerQuestions,
              myAnswersFilter: myAnswerFilter,
              myAnswerPagination
            }
          });
        })
        .catch(() => {
          dispatch({
            type: questions.GET_MY_ANSWER_FAIL
          });
        });
    };
  },

  getAllQuestionAndAnswers({ page, dirId, practiceId, professionId, search, categoryId }) {
    return dispatch => {
      dispatch(this.setLoading(true));
      const searchValue = search ? { search } : {};

      const filterParams = queryString.stringify(
        { dir_id: dirId, practice_id: practiceId, profession_id: professionId, category_id: categoryId, page: page || 0, ...searchValue },
        { arrayFormat: "index" }
      );

      axios({
        method: "get",
        responseType: "json",
        url: `/members/json-api/qa/all-questions?${filterParams}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(data => {
          const { questions: myAnswerQuestions, filters: myAnswerFilter, ...myAnswerPagination } = data.data;

          dispatch({
            type: questions.GET_ALL_MY_ANSWER,
            payload: {
              myAnswers: myAnswerQuestions,
              myAnswersFilter: myAnswerFilter,
              myAnswerPagination
            }
          });
        })
        .catch(() => {
          dispatch({
            type: questions.GET_MY_ANSWER_FAIL
          });
        });
    };
  },

  setLoading(status) {
    return {
      type: questions.SET_QA_PRELOADER,
      payload: status
    };
  },
  sendReply({ answer_id, answer }) {
    return dispatch => {
      dispatch(this.setLoading(true));
      dispatch(this.updateQuestionAnswers(false));
      axios({
        method: "post",
        url: "/members/json-api/qa/add-answer",
        data: JSON.stringify({
          answer_id,
          answer
        })
      })
        .then(({ data }) => {
          dispatch(this.updateQuestionAnswers(data.ok));
        })
        .catch(error => {
          console.error("sendReply", error);
        })
        .finally(() => {
          dispatch(this.setLoading(false));
        });
    };
  },
  sendComment({ answer_id, comment }) {
    return dispatch => {
      dispatch(this.setLoading(true));
      dispatch(this.updateQuestionAnswers(false));
      axios({
        method: "post",
        url: "/members/json-api/qa/add-comment",
        data: JSON.stringify({
          answer_id,
          comment
        })
      })
        .then(({ data }) => {
          dispatch(this.updateQuestionAnswers(data.ok));
        })
        .catch(error => {
          console.error("sendComment", error);
        })
        .finally(() => {
          dispatch(this.setLoading(false));
        });
    };
  },
  saveQuestion(questionId) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/qa/save-question",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({ question_id: questionId })
      })
        .then(() => {
          dispatch({ type: questions.QA_MARK_SAVED, payload: questionId });
        })
        .catch(() => dispatch(this.setLoading(false)));
    };
  },
  unSaveQuestion(questionId) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/qa/unsave-question",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({ question_id: questionId })
      })
        .then(() => {
          dispatch({ type: questions.QA_MARK_UNSAVED, payload: questionId });
        })
        .catch(() => dispatch(this.setLoading(false)));
    };
  },

  saveSingleQuestion(questionId) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/qa/save-question",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({ question_id: questionId })
      })
        .then(() => {
          dispatch({ type: questions.QA_MARK_SAVED_SINGLE, payload: questionId });
        })
        .catch(() => dispatch(this.setLoading(false)));
    };
  },
  unSaveSingleQuestion(questionId) {
    return dispatch => {
      dispatch(this.setLoading(true));
      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/qa/unsave-question",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify({ question_id: questionId })
      })
        .then(() => {
          dispatch({ type: questions.QA_MARK_UNSAVED_SINGLE, payload: questionId });
        })
        .catch(() => dispatch(this.setLoading(false)));
    };
  },

  updateQuestionAnswers(bool) {
    return {
      type: questions.QA_UPDATE_ANSWERS,
      payload: bool
    };
  },

  cleanQuestions() {
    return {
      type: questions.CLEAN_QUESTIONS
    };
  },

  cleanCurrentQuestions() {
    return {
      type: questions.CLEAN_CURRENT_QUESTIONS
    };
  }
};
