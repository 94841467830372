import React, { useState, useEffect } from "react";
import cn from "classnames";
import { formatDistanceToNow } from "date-fns";

import styles from "./profileListing.module.css";
import { Button } from "../../common/button";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
import { ReactComponent as PhoneIcon } from "../../../media/icons/phone.svg";

const ProfileListing = ({ isExpand, list, addToFavorite, removeFavorite }) => {
  return (
    <div className={cn(styles.flex, isExpand ? styles.flexExpand : "")}>
      {list.map(item => {
        return <Profile isExpand={isExpand} item={item} addToFavorite={addToFavorite} removeFavorite={removeFavorite} key={item.title} />;
      })}
    </div>
  );
};

const Profile = ({ item, isExpand, removeFavorite, addToFavorite }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = listingId => {
    if (isFavorite) {
      removeFavorite(listingId);
      setIsFavorite(false);
    } else {
      addToFavorite(listingId);
      setIsFavorite(true);
    }
  };

  useEffect(() => {
    if (item.bookmarked === "true") {
      setIsFavorite(true);
    }
  }, []);

  return (
    <div className={cn(styles.tile, isExpand ? styles.tileExpand : "")} key={item.title}>
      <div className={styles.favorites}>
        <Button
          className={cn(styles.favoritesButton, isFavorite ? styles.favoritesButtonActive : "")}
          onClick={() => toggleFavorite(item.listing_id)}
        >
          <SaveIcon />
        </Button>
      </div>

      <div className={styles.heading}>
        <div className={styles.tileImage}>
          <img src={item.cover_image_url} alt="Profile banner" />
        </div>
        <div className={styles.headingContent}>
          <div className={styles.headingFlex}>
            <h6 className={styles.tileTitle}>{item.attorney}</h6>
            {item.is_premium && <span className={cn(styles.sponsor, styles.sponsorTablet)}>Sponsor</span>}
          </div>
          {item.is_premium && <span className={cn(styles.sponsor, styles.sponsorMobile)}>Sponsor</span>}

          {item.lawfirms.length ? (
            <div className={cn(styles.locationContent, styles.locationContentMobile)}>
              {item.lawfirms.map(locationItem => (
                <p className={styles.location}>{`${locationItem.city}${locationItem.state ? `, ${locationItem.state}` : ""}`}</p>
              ))}
            </div>
          ) : null}

          <div className={styles.tileInfoTablet}>
            {item.lawfirms.length ? (
              <div className={styles.locationContent}>
                {item.lawfirms.map(locationItem => (
                  <p className={styles.location}>{`${locationItem.city}${locationItem.state ? `, ${locationItem.state}` : ""}`}</p>
                ))}
              </div>
            ) : null}

            {item.practices.length ? (
              <div className={styles.practicesContent}>
                {item.practices.map(practiceItem => (
                  <p className={styles.practices} key={practiceItem.practice_title}>
                    {practiceItem.practice_title}
                  </p>
                ))}
              </div>
            ) : null}

            <div className={styles.tileFlex}>
              {item.added && <p className={styles.experience}>{formatDistanceToNow(new Date(item.added))} Licensed</p>}
              {item.phone ? (
                <a href={`tel:${item.phone}`} className={styles.phone}>
                  <PhoneIcon /> {item.phone}
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tileInfoMobile}>
        {item.practices.length ? (
          <div className={styles.practicesContent}>
            {item.practices.map(practiceItem => (
              <p className={styles.practices}>{practiceItem.practice_title}</p>
            ))}
          </div>
        ) : null}
        <div className={styles.tileFlex}>
          {item.added && <p className={styles.experience}>{formatDistanceToNow(new Date(item.added))} Licensed</p>}
          {item.phone ? (
            <a href={item.phone} className={styles.phone}>
              <PhoneIcon /> {item.phone}
            </a>
          ) : null}
        </div>
      </div>

      <p className={styles.tileDescription}>{item.description}</p>
    </div>
  );
};

export default ProfileListing;
