import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { add } from "date-fns";
import { useFormik } from "formik";
import cn from "classnames";

import Input from "../input";
import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Select from "../select";
import { detectDuplicateAllFields } from "../../../helpers";
import { getCountryNameFromCode, getStateNameFromCode, renderSelectCountries, renderSelectStates } from "../../../helpers/select";
import CalendarWithInputMask from "../calendarWithInputMask";
import DropdownActions from "../dropdownActions";
import styles from "./licenses.module.css";
import { useValidationError } from "../../../helpers/hooks";

const Licenses = ({ states, countries, validation, selectedLicenses, submitChanges, showRequiredText }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }
  const { t } = useTranslation();
  const refButton = useRef();

  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isNotRequired = !Object.keys(validation).length;
  const isRequiredText = validation.required && !selectedLicenses?.length && showRequiredText;
  const [mainErrors, setMainErrors] = useState({});
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);

  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedLicenses);
    } else {
      const newList = selectedList.map((item, index) => {
        return selectedLicenses[index] ? selectedLicenses[index] : item;
      });
      setSelectedList(newList);
    }
  }, [selectedLicenses.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ licenses: updatedList }, "licenses", "save_license");
    }
  };

  const defaultField = { acquired_date: "", location: "", location_state: "", number: "", name: "", isNew: true };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newList);
      onSubmit(newList);
    } else {
      const newList = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newList);
      onSubmit(newList);
    }
  };

  const handleAddEmpty = () => {
    if (refButton?.current && selectedList.filter(item => item.isNew).length) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([...selectedList, defaultField]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const selectedLength = selectedLicenses.length;
  const title = `${validation?.custom_name || `License${selectedLength > 1 ? "s" : ""}`}${selectedLength ? ` (${selectedLength})` : ""}`;

  return (
    <Dropdown
      description={validation.custom_description}
      isRequired={isRequired}
      title={title}
      isRequiredText={isRequiredText}
      handleMouseMove={handleMouseMove}
    >
      <div>
        <div>
          {selectedList.map((item, index) => {
            return (
              <LicensesItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.number}-licenses-${item.acquired_date}-index-${index}`}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
                handleRemoveEmpty={handleRemove}
                selected={item}
                index={index}
                states={states}
                countries={countries}
                isRequired={isRequired}
                isNotRequired={isNotRequired}
                selectedLicenses={selectedLicenses}
                refButton={refButton}
                setMainErrors={setMainErrors}
                mainErrors={mainErrors}
                isEdited={isEdited}
                setIsEdited={setIsEdited}
              />
            );
          })}
        </div>
      </div>
      <DropdownActions buttonHandle={handleAddEmpty} buttonText={t("dashboard_single_listing_addlicense")} />
    </Dropdown>
  );
};

const LicensesItem = ({
  countries,
  states,
  selected,
  handleSubmit: onSubmit,
  index,
  handleRemove,
  handleRemoveEmpty,
  isRequired,
  isNotRequired,
  selectedLicenses,
  refButton,
  setMainErrors,
  mainErrors,
  isEdited,
  setIsEdited
}) => {
  const { t } = useTranslation();
  const { isNew } = selected;
  const isHideInfo = index > 0;
  const isFirst = index === 0;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const validate = values => {
    const { acquired_date, location, location_state, number, name } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");
    const duplicateText = "Such item has been added already";

    if (!acquired_date) {
      errors.acquired_date = requiredText;
    } else if (acquired_date === "Invalid date") {
      errors.acquired_date = "Invalid date format";
    }

    if (number && detectDuplicateAllFields(selectedLicenses, { acquired_date, location, location_state, number }, index)) {
      errors.number = duplicateText;
    }
    if (!number) {
      errors.number = requiredText;
    }

    if (!name) {
      errors.name = requiredText;
    }

    if (!location || !location.title) {
      errors.location = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...selected,
      location: { title: selected.location },
      location_state: { title: selected.location_state },
      isEdited: false
    },
    onSubmit: values => {
      const { number, location, acquired_date, location_state, name, prkey } = values;

      return onSubmit(
        {
          number,
          location: location.title,
          location_state: location_state.title,
          prkey,
          name,
          acquired_date: format(add(new Date(acquired_date), { hours: 12 }), "yyyy-MM-dd")
        },
        isNew,
        index
      );
    },
    validate
  });

  const { values, errors, setFieldTouched, setFieldError, handleSubmit, setFieldValue } = formik;

  const handleChange = e => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue(e.target.name, e.target.value);
  };

  const handleChangeDate = date => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
    if (!isEdited) {
      setIsEdited(true);
    }
    setFieldValue("acquired_date", date);
  };

  const handleChangeInput = value => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
    if (!isEdited) {
      setIsEdited(true);
    }

    setFieldValue("acquired_date", value);
  };

  const handleSelectChange = (newValue, actionMeta) => {
    if (!isEdited) {
      setIsEdited(true);
    }
    setFieldValue(actionMeta.name, newValue);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    setFieldError("location_state", "");
  };

  const handleChangeCountries = (newValue, actionMeta) => {
    if (!isEdited) {
      setIsEdited(true);
    }

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    setFieldValue(actionMeta.name, newValue);
    setFieldValue("location_state", "");
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const onHandleSubmit = () => {
    setFieldTouched("name");
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (
        (values.name || values.number || values.location || values.acquired_date || values.location_state) &&
        values.isEdited &&
        !Object.values(errors).some(item => item)
      ) {
        handleSubmit();
        setFieldValue("isEdited", false);
      }
      setIsEdited(false);
    }
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.name || values.number || values.location || values.acquired_date || values.location_state) &&
            values.isEdited &&
            isEdited &&
            !Object.values(errors).some(item => item)
          ) {
            handleSubmit();
            setFieldValue("isEdited", false);
          }
          setIsEdited(false);
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, values.number, values.location, values.acquired_date, values.location_state]);

  // eslint-disable-next-line
  const countryOptions = renderSelectCountries(countries, false).map((item, index) => ({ id: index, title: item }));
  // eslint-disable-next-line
  const stateOptions = renderSelectStates(values.location.title, states, false).map((item, index) => ({ id: index, title: item }));

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { licenses: "Error" } : "isRemove", "licenses");

  return (
    <div
      className={cn(styles.association, isFirst ? styles.associationFirst : "")}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      ref={containerRef}
    >
      <span className={styles.name}>License Name</span>

      <div className={cn(styles.associationName, styles.licensesName)}>
        <Input
          placeholder={t("dashboard_listings_edit_license_name_placeholder")}
          error={errors.name}
          id="name"
          isInvalid={errors.name}
          name="name"
          onBlur={() => handleBlur("name")}
          onChange={handleChange}
          required={isNotRequired}
          value={values.name}
          errorClassName={styles.error}
        />
      </div>

      <RemoveButton className={styles.removeButton} onClick={onRemove} />

      <div className={styles.associationName}>
        <Input
          placeholder={t("dashboard_listings_edit_license_number_placeholder")}
          error={errors.number}
          id="number"
          isInvalid={errors.number}
          name="number"
          onBlur={() => handleBlur("number")}
          onChange={handleChange}
          required={isNotRequired}
          value={values.number}
          errorClassName={styles.error}
        />
      </div>

      <div className={styles.flex}>
        <div className={styles.inputContent}>
          <span className={cn(styles.label, isHideInfo ? styles.labelHideDesktop : "")}>{t("dashboard_common_country")}</span>
          <Select
            error={errors.location}
            isInvalid={errors.location}
            getOptionLabel={option => getCountryNameFromCode(countries, option.title)}
            getOptionValue={option => option.title}
            inputId="location"
            name="location"
            options={countryOptions}
            handleChange={handleChangeCountries}
            required={isRequired}
            value={[values.location]}
            classNameWrap={styles.select}
            placeholder={t("dashboard_common_country")}
            classNameError={styles.error}
          />
        </div>
        <div className={styles.inputContent}>
          <span className={cn(styles.label, isHideInfo ? styles.labelHideDesktop : "")}>{t("dashboard_common_state")}</span>
          <Select
            error={errors.location_state}
            getOptionLabel={option => getStateNameFromCode(states, option.title)}
            getOptionValue={option => option.title}
            id="location_state"
            inputId="location_state"
            isInvalid={errors.location_state}
            name="location_state"
            noOptionsMessage={() => "Please select your country first"}
            handleChange={handleSelectChange}
            options={states && stateOptions}
            required={isNotRequired}
            value={[values.location_state]}
            placeholder={t("dashboard_common_state")}
            classNameWrap={styles.select}
          />
        </div>

        <div className={styles.inputContent}>
          <span className={cn(styles.label, isHideInfo ? styles.labelHideDesktop : "")}>
            {t("dashboard_listings_edit_license_acquired_column")}
          </span>
          <CalendarWithInputMask
            error={errors.acquired_date}
            isInvalid={errors.acquired_date}
            value={values.acquired_date ? new Date(values.acquired_date) : null}
            onChange={date => handleChangeDate(date)}
            handleChangeInput={handleChangeInput}
            required={isNotRequired}
            placeholder="MM/DD/YYYY"
            handleSubmit={handleSubmit}
            classNameError={styles.error}
            defaultMaxDate={new Date()}
          />
        </div>

        <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
      </div>
    </div>
  );
};

export default Licenses;
