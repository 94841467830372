import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { format, parse } from "date-fns";
import { useFormik } from "formik";

import { ReactComponent as AddIcon } from "../../../media/icons/addCycle.svg";
import { ReactComponent as DeleteIcon } from "../../../media/icons/deleteCycle.svg";
import TimePickerInput from "../../common/timePickerInput";
import styles from "./workingHours.module.css";

const defaultDays = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {} };
const currentDay = { 1: "Monday", 2: "Tuesday", 3: "Wednesday", 4: "Thursday", 5: "Friday", 6: "Saturday", 7: "Sunday" };

const WorkingHours = ({ days, handleSelectSubHours }) => {
  const { t } = useTranslation();
  const [showListOfDates, setShowListOfDates] = useState(false);
  const [activeDays, setActiveDays] = useState([]);

  const normalizeDays = Object.keys(defaultDays).map((item, index) => {
    return { hours: activeDays[item] || [], day: currentDay[item], currentIndex: index + 1 };
  });

  const toggleShowListOfDates = () => {
    setShowListOfDates(prev => !prev);
  };

  useEffect(() => {
    if (!activeDays.length && !Object.keys(activeDays).length) {
      setActiveDays(days);
    }
  }, [days]);

  useEffect(() => {
    if (!showListOfDates && Object.keys(days).length) {
      setShowListOfDates(true);
    }
  }, [days.length]);

  const handleSubmit = (newActiveDays, index) => {
    const newActiveDaysList = { ...activeDays, [index + 1]: newActiveDays };
    setActiveDays(newActiveDaysList);
    handleSelectSubHours(newActiveDaysList);
  };

  return (
    <div>
      <div className={styles.head}>
        <p className={styles.name}>{t("dashboard_listings_edit_location_hours")}</p>
        <button type="button" className={styles.button} onClick={toggleShowListOfDates}>
          {showListOfDates ? t("remove") : t("add")}
        </button>
      </div>
      {showListOfDates ? (
        <>
          {normalizeDays.map((item, index) => {
            return (
              <WorkingDayItem
                key={item.day}
                day={item.day}
                hours={item.hours}
                onSubmit={(newActiveDays, dateIndex) => handleSubmit(newActiveDays, index, dateIndex)}
              />
            );
          })}
        </>
      ) : null}
    </div>
  );
};

const WorkingDayItem = ({ day, hours, onSubmit }) => {
  const [activeHours, setActiveHours] = useState([]);
  const dateSymbolActive = activeHours.length ? styles.dateSymbolActive : "";

  const handleAddEmptyHorse = () => {
    setActiveHours([...activeHours, { start: new Date(), end: new Date() }]);
  };

  const handleRemove = index => {
    const updatedHours = activeHours.filter((_, horseIndex) => {
      return index !== horseIndex;
    });

    setActiveHours(updatedHours);
    onSubmit(updatedHours);
  };

  const handleSubmit = (newTime, index) => {
    const updatedHours = activeHours.map((item, currentIndex) => {
      if (currentIndex + 1 === index) {
        return {
          ...item,
          ...newTime
        };
      }
      return item;
    });

    setActiveHours(updatedHours);
    onSubmit(updatedHours, index);
  };

  useEffect(() => {
    if (!activeHours.length) {
      setActiveHours(hours);
    }
  }, [hours]);

  return (
    <div className={styles.item}>
      <span className={cn(styles.dateSymbol, dateSymbolActive)}>{day[0]}</span>

      <div>
        {activeHours.length ? (
          <>
            {activeHours.map((item, index) => {
              return (
                <WorkingHoursItem
                  /* eslint-disable-next-line */
                  key={`day-${index}`}
                  index={index}
                  handleRemove={() => handleRemove(index)}
                  selected={item}
                  handleAdd={handleAddEmptyHorse}
                  onSubmit={handleSubmit}
                />
              );
            })}
          </>
        ) : (
          <button type="button" className={styles.add} onClick={handleAddEmptyHorse}>
            <AddIcon />
          </button>
        )}
      </div>
    </div>
  );
};

const WorkingHoursItem = ({ handleRemove, selected, handleAdd, index, onSubmit }) => {
  const { t } = useTranslation();

  const validate = values => {
    const { start, end } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!start) {
      errors.start = requiredText;
    } else if (start === "Invalid date") {
      errors.start = "Invalid date format";
    }

    if (!end) {
      errors.end = requiredText;
    } else if (start === "Invalid date") {
      errors.end = "Invalid date format";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      ...selected,
      isEdited: false
    },
    onSubmit: values => {
      const { isEdited, ...rest } = values;

      const start = rest.start && rest.start.toString().length > 8 ? format(values.start, "HH:mm:ss") : rest.start;
      const end = rest.end && rest.end.toString().length > 8 ? format(values.end, "HH:mm:ss") : rest.end;

      onSubmit({ ...rest, start, end }, index + 1);
    },
    validate
  });

  const { values, setFieldValue, setFieldTouched, handleSubmit, errors } = formik;

  const handleChangeStartDate = date => {
    formik.setFieldValue("start", date);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const handleChangeEndDate = date => {
    formik.setFieldValue("end", date);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
  };

  const handleStartDateError = reason => {
    if (reason && errors.start_date_time !== reason) {
      formik.setFieldError("start", reason);

      if (!values.isEdited) {
        setFieldValue("isEdited", true);
      }
    }
  };

  const handleRepeatEndDateError = reason => {
    if (reason && errors.repeat_end_count !== reason) {
      formik.setFieldError("end", reason);

      if (!values.isEdited) {
        setFieldValue("isEdited", true);
      }
    }
  };

  const handleBlur = fieldName => {
    setFieldTouched(fieldName, true);
  };

  useEffect(() => {
    if ((values.start || values.end) && values.isEdited && !Object.keys(errors).length) {
      handleSubmit();
    }
  }, [values, document.activeElement.tagName, errors]); // eslint-disable-line

  const startValue = () => {
    if (values.start) {
      if (values.start.length <= 10) {
        return parse(values.start, "HH:mm:ss", new Date());
      }

      return values.start;
    }

    return null;
  };

  const endValue = () => {
    if (values.end) {
      if (values.end.length <= 10) {
        return parse(values.end, "HH:mm:ss", new Date());
      }

      return values.end;
    }

    return null;
  };

  return (
    <div className={styles.hoursItem}>
      <div className={styles.hoursDetailItem}>
        <TimePickerInput
          isInvalid={errors.start}
          name="start"
          id="start"
          value={startValue()}
          onBlur={() => handleBlur("start")}
          onChange={date => handleChangeStartDate(date)}
          onError={handleStartDateError}
          views={["day"]}
          classNameWrap={styles.dateFullDate}
          classNameError={styles.errorText}
          isDisableMaxDay
          isGrey
        />
        <span className={styles.divider} />

        <TimePickerInput
          isInvalid={errors.end}
          name="end"
          id="end"
          value={endValue()}
          onBlur={() => handleBlur("v")}
          onChange={date => handleChangeEndDate(date)}
          onError={handleRepeatEndDateError}
          views={["day"]}
          classNameWrap={styles.dateFullDate}
          classNameError={styles.errorText}
          isDisableMaxDay
          isGrey
        />

        <button type="button" className={styles.add} onClick={handleRemove}>
          <DeleteIcon />
        </button>

        {index === 0 ? (
          <button type="button" className={styles.add} onClick={handleAdd}>
            <AddIcon />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default WorkingHours;
