import React from "react";

import { Modal } from "../../../common/modal";
import successIcon from "../../../../media/icons/success.svg";
import ModalControl from "../../../modalControl";
import styles from "./questionFormSuccess.module.css";

const QuestionFormSuccess = ({ clearAllModals }) => {
  return (
    <Modal closeModal={clearAllModals} clearAllModals={clearAllModals} classNameMain={styles.modal}>
      <div className={styles.form}>
        <section className={styles.content}>
          <img src={successIcon} alt="success" className={styles.icon} />
          <p className={styles.message}>Profile Information Added.</p>
        </section>

        <ModalControl onExit={clearAllModals} isHideExitConfirm isClose />
      </div>
    </Modal>
  );
};

export default QuestionFormSuccess;
