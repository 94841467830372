import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./search.module.css";

export const Search = ({ handleFiltrationSearch, isReferrals, children }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isSearch) {
        handleFiltrationSearch(searchTerm);
      }

      if (!isSearch) {
        setIsSearch(true);
      }
    }, 1000);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTerm]); // eslint-disable-line

  useEffect(() => {
    if (searchTerm) {
      setSearchTerm("");
    }

    if (isSearch) {
      setIsSearch(false);
    }
  }, [isReferrals]); // eslint-disable-line

  return (
    <div className={styles.flex}>
      <div className={styles.wrapper}>
        <button type="button" className={styles.searchIcon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="9.16634" cy="9.16683" r="5.83333" stroke="#ABAFB9" strokeWidth="1.6" />
            <path d="M16.667 16.6665L14.167 14.1665" stroke="#ABAFB9" strokeWidth="1.6" strokeLinecap="round" />
          </svg>
        </button>
        <input
          type="text"
          className={styles.search}
          placeholder={t("dashboard_ppl_search_input")}
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      </div>
      {children ? <>{children}</> : null}
    </div>
  );
};
