import React, { useCallback, useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
// import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import SelectInput from "../../common/selectInput";
// import { customStyles as selectStyles } from "../../../constants/select";
// import { CopyListing } from "../copyListing";
import { Directories } from "../directories";
import styles from "./addFreeListing.module.css";
import { actions as homeActions } from "../../../actions/home";

/* todo */
export const AddFreeListing = ({ isModal, listingModalListingType }) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();

  // const [showCopy, setShowCopy] = useState(false);
  // eslint-disable-next-line
  const [categories, setCategories] = useState([]);
  // const [viewMore, setViewMore] = useState(false);
  const history = useHistory();
  const isMyEventsPage = history.location.pathname.includes("/my-events") || listingModalListingType.includes("/my-events");
  const isMyJobsPage = history.location.pathname.includes("/my-jobs") || listingModalListingType.includes("/my-jobs");
  const isMyOrganizationsPage =
    history.location.pathname.includes("/my-organizations") || listingModalListingType.includes("/my-organizations");
  const selectRef = useRef();

  const { addNewListingData } = useSelector(state => state.home);
  const addNewListing = useCallback(
    (data, listingType) => dispatch(homeActions.addNewListing(data, history, window.location.href, listingType)),
    [dispatch]
  ); // eslint-disable-line

  const handleSubmit = values => {
    const data = {
      category_id: values.category.category_id,
      listing_id: values.listing.listing_id,
      attorney: ""
    };

    addNewListing(data, values.profession.title);
  };

  const formik = useFormik({
    initialValues: {
      category: { category_id: "", profession_id: "", title: "Select" },
      listing: { listing_id: "", membership: "Select" },
      profession: { profession_id: "", title: "Select" },
      submitted: false
    },
    onSubmit: values => {
      handleSubmit(values);
    }
  });
  /*

  const handleShowCopy = () => {
    setShowCopy(true);
  };

  const handleHideCopy = () => {
    setShowCopy(false);
  };

  const handleChangeCopyListing = newValue => {
    const findProfession = addNewListingData.professions.find(item => String(item.profession_id) === String(newValue.profession_id));
    const findCategory = addNewListingData.categories.find(item => newValue.membership.includes(item.title));

    formik.setFieldValue("listing", newValue);

    if (findProfession) {
      formik.setFieldValue("profession", findProfession);

      const newCategories = addNewListingData.categories.filter(
        item => String(item.profession_id) === String(findProfession.profession_id)
      );

      setCategories(newCategories);
    }

    if (findCategory) {
      formik.setFieldValue("category", findCategory);
    }

    if (!newValue.profession_id) {
      formik.setFieldValue("category", { category_id: "", profession_id: "", title: "Select" });
      formik.setFieldValue("profession", { profession_id: "", title: "Select" });
    }
  };
  */

  const handleChangeProfessional = newValue => {
    let professionIds = [newValue.profession_id].map(item => String(item));

    if (newValue.title === "Organizations") {
      professionIds = addNewListingData.professions
        .filter(item => item.title.includes("Organizations"))
        .map(item => String(item.profession_id));
    }

    const newCategories = addNewListingData.categories.filter(item => professionIds.includes(String(item.profession_id)));

    setCategories(newCategories);

    if (selectRef.current) {
      selectRef.current.focus();
      if (formik.values.profession.title !== newValue.title) {
        formik.setFieldValue("category", { category_id: "", profession_id: "", title: "Select" });
      }
    }

    formik.setFieldValue("profession", newValue);

    if (["Jobs", "Events", "Organizations"].includes(newValue.title) && newCategories.length) {
      handleChangeCategory(newCategories[0]);
    }

    handleSubmitListing();
  };

  const handleChangeCategory = newValue => {
    formik.setFieldValue("category", newValue);

    const newCategories = addNewListingData.categories.filter(item => String(item.profession_id) === String(newValue.profession_id));
    setCategories(newCategories);
  };
  /*
  const handleBlur = fieldName => {
    formik.setFieldTouched(fieldName, true);
  };

  const isShowSelectDirectory = !["Events", "Businesses / Organizations", "Jobs"].includes(formik.values.profession.title);
  const disabledButton = !formik.values.category?.category_id ? styles.disabled : "";

  const groupCategories = useMemo(() => {
    const sortedCategories = categories.sort((a, b) => Number(a.group_id) - Number(b.group_id));

    if (sortedCategories) {
      const getKeysOfSortedElements = sortedCategories.reduce((acc, current, index) => {
        acc[current.group_id] = index;
        return acc;
      }, {});

      return sortedCategories.map((item, index) => {
        if (Object.values(getKeysOfSortedElements).includes(index)) {
          return { ...item, isAddBorder: true };
        }

        return item;
      });
    }

    return [];
  }, [categories.length, formik.values.profession.title]);
  */

  useEffect(() => {
    if (isMyJobsPage || isMyEventsPage || isMyOrganizationsPage) {
      let findCurrentProfession = "";
      if (isMyJobsPage) {
        findCurrentProfession = addNewListingData.professions.find(item => item.title === "Jobs");
      } else if (isMyEventsPage) {
        findCurrentProfession = addNewListingData.professions.find(item => item.title === "Events");
      } else {
        findCurrentProfession = addNewListingData.professions.find(item => item.title.includes("Organizations"));
      }

      handleChangeProfessional(findCurrentProfession);
    }
  }, [isMyJobsPage, isMyEventsPage, isMyOrganizationsPage]);

  useEffect(() => {
    if (
      !formik.values.submitted &&
      (isMyJobsPage || isMyEventsPage || isMyOrganizationsPage) &&
      formik.values.profession.title &&
      formik.values.category.title
    ) {
      formik.handleSubmit();
      formik.setFieldValue("submitted", true);
    }
  }, [formik.values.category.title]);

  const handleSubmitListing = () => {
    if (!isMyOrganizationsPage && !isMyJobsPage && !isMyEventsPage) {
      formik.handleSubmit();
    }
  };

  return (
    <>
      {isMyJobsPage || isMyEventsPage || isMyOrganizationsPage ? null : (
        <>
          <div className={styles.content}>
            <h2 className={styles.title}>Add new listing</h2>
            <h2 className={styles.description}>Select listing type</h2>
            {/*
            {!showCopy && addNewListingData.listings && (
              <p className={styles.copyLabel}>
                {t("addlisting_title_copy")}
                <button type="button" className={styles.copyLink} onClick={handleShowCopy}>
                  {t("addliting_copy_display")}
                </button>
              </p>
            )}
            {showCopy && (
              <CopyListing
                className={styles.copyListing}
                formik={formik}
                existingListings={addNewListingData.listings}
                handleSelectChange={handleChangeCopyListing}
                isHomePage
                onClose={handleHideCopy}
              />
            )}
            <p className={styles.label}>
              {t("addlisting_title_group")} <span>*</span>
            </p>
            */}
            <Directories
              activeProfessional={formik.values.profession}
              professions={addNewListingData.professions.filter(item => item.title.toLowerCase() !== "people")}
              handleChangeProfessional={handleChangeProfessional}
              isViewMore
              isModal={isModal}
            />
            {/* todo: remove */}
            {/*
        {viewMore ? (
          <button type="button" className={styles.viewMoreButton} onClick={() => setViewMore(false)}>
            {t("viewless")}
          </button>
        ) : (
          <button type="button" className={styles.viewMoreButton} onClick={() => setViewMore(true)}>
            {t("viewmore")}
          </button>
        )}
        
            <div className={cn(styles.directoryContent, !isShowSelectDirectory ? styles.directoryContentHide : "")}>
              <p className={styles.label}>
                {t("addlisting_title_dir")} <span>*</span>
              </p>
              <SelectInput
                placeholder={t("select")}
                getOptionLabel={({ title }) => title}
                getOptionValue={({ category_id }) => category_id}
                error={formik.errors.category}
                isInvalid={formik.errors.category && formik.touched.category}
                name="category"
                noOptionsMessage={() => "Sorry, there are no directories matching profession"}
                onBlur={() => handleBlur("category")}
                onChange={handleChangeCategory}
                options={groupCategories}
                styles={selectStyles}
                value={[formik.values.category]}
                isAddCustomScrollbar
                selectRef={selectRef}
              />
            </div>
            */}
          </div>
          {/*
          <ModalControl
            onExit={handleClose}
            onConfirm={handleSubmitListing}
            confirmationType="button"
            confirmClassName={disabledButton}
            isAddHelpButton
            isClose
          >
            {t("addlisting_addfreelisting")}
          </ModalControl>
          */}
        </>
      )}
    </>
  );
};
