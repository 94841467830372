import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import SelectInput from "../selectInput";
import styles from "./pagination.module.css";

const sortList = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "100", label: "100" }
];

export const Pagination = ({ className, links, currentPage, lastPage, perPage, handlePerPage, isStartFromFirst }) => {
  const [valuePerPage, setValuePerPage] = useState({ value: "", label: "10" });

  const currentPageLink = links.find(({ number }) => {
    return number === currentPage;
  });

  const renderPageLinks = () => {
    let startPage;
    let endPage;
    const totalPages = links.length;

    if (totalPages <= 10) {
      startPage = 0;
      endPage = totalPages;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (currentPage <= 6) {
        startPage = 0;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    const surroundLinks = links.slice(startPage, endPage);

    return (
      <div className={styles.linkRowWrp}>
        {surroundLinks.map(({ pathname, search, number }) => {
          return (
            <Link
              to={{ pathname, search }}
              key={search + number}
              className={cn(styles.paginationItem, currentPage === number ? styles.paginationItemActive : "")}
            >
              {isStartFromFirst ? number : number + 1}
            </Link>
          );
        })}
      </div>
    );
  };

  const renderArrowLinks = isPrev => {
    const newParams = currentPageLink?.search?.replace(/(page=)[^&]+/, `page=${isPrev ? currentPage - 1 : currentPage + 1}`);

    if (isPrev) {
      return (
        <Link
          to={{ pathname: currentPageLink?.pathname, search: newParams }}
          className={cn(styles.linkWrp, currentPage === 0 ? styles.linkWrpDisable : "")}
        >
          Prev
        </Link>
      );
    }

    return (
      <Link
        to={{ pathname: currentPageLink?.pathname, search: newParams }}
        className={cn(styles.linkWrp, currentPageLink?.number === lastPage ? styles.linkWrpDisable : "")}
      >
        Next
      </Link>
    );
  };

  useEffect(() => {
    if (String(valuePerPage.value) !== String(perPage)) {
      setValuePerPage({ value: perPage, label: perPage });
    }
  }, [perPage]);

  return (
    <nav className={cn(styles.pagination, className)}>
      {perPage && (
        <div className={styles.perPageContent}>
          <span className={styles.perPageText}>Items per page</span>
          <SelectInput
            className={styles.select}
            name="perPage"
            placeholder="10"
            onChange={item => {
              handlePerPage(item.value);
              setValuePerPage(item);
            }}
            options={sortList}
            value={valuePerPage}
          />
        </div>
      )}

      {renderArrowLinks(true)}
      {renderPageLinks()}
      {renderArrowLinks()}
    </nav>
  );
};
