import React, { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import simCard from "../../../media/icons/simcard.svg";
import { Button } from "../button";
import { renderBrandIcon } from "../../../helpers";
import { PopupActions } from "../popupActions";
import { modals as modalsConstant } from "../../../constants/listings";
import styles from "../../payments/paymentMethods/paymentMethods.module.css";
import actionStyles from "../popupActions/popupActions.module.css";

export const SelectedCardBlock = ({ deleteCard, defaultSource, selectedCard, updateDefaultSource, openModal }) => {
  const [actionPrompt, setActionPrompt] = useState(null);
  const { t } = useTranslation();

  const handleEdit = () => {
    openModal(modalsConstant.editCard);
  };

  const handleMakeDefault = cardId => updateDefaultSource(cardId);
  const handleDelete = () => deleteCard({ id: selectedCard.id, object: selectedCard.object, deleted: true });

  const closePrompt = () => {
    setActionPrompt(true);
  };

  return (
    <React.Fragment>
      <div className={styles.cardWrapperComp}>
        <div className={styles.creditCard}>
          <div className={styles.cardIconRow}>
            <img className={styles.cardIconSim} src={simCard} alt="" />
            {selectedCard && <img src={renderBrandIcon(selectedCard.brand)} alt="" />}
          </div>
          <div className={styles.creditNumber}>
            <span className={styles.cardNumber}>****</span>
            <span className={styles.cardNumber}>-</span>
            <span className={styles.cardNumber}>****</span>
            <span className={styles.cardNumber}>-</span>
            <span className={styles.cardNumber}>****</span>
            <span className={styles.cardNumber}>-</span>
            <span>{selectedCard ? selectedCard.last4 : "****"}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>{selectedCard ? selectedCard.name : "Cardholder Name"}</span>
            <span>{selectedCard ? `${selectedCard.exp_month}/${selectedCard.exp_year}` : "MM/YY"}</span>
          </div>
        </div>

        <div className={styles.cardControl}>
          {selectedCard ? (
            <React.Fragment>
              <Button className={cn("button--clear", styles.buttonEdit)} onClick={handleEdit}>
                Edit Card Details
              </Button>
              {defaultSource?.id !== selectedCard.id && (
                <Button className={cn("button--clear", styles.buttonEdit)} onClick={() => handleMakeDefault(selectedCard.id)}>
                  Make Default
                </Button>
              )}

              <PopupActions
                promptRoot="Delete this Card"
                promptClass={styles.deletePrompt}
                promptRootClass={cn("button--clear", styles.buttonDelete)}
                close={actionPrompt}
                setActionPrompt={setActionPrompt}
              >
                <p className={actionStyles.warning}>Are you sure you want to delete the card?</p>
                <div className={actionStyles.promptWrap}>
                  <Button className={actionStyles.cancel} onClick={closePrompt}>
                    {t("dashboard_common_button_cancel")}
                  </Button>
                  <Button className={actionStyles.confirm} onClick={handleDelete}>
                    {t("dashboard_common_button_yes")}
                  </Button>
                </div>
              </PopupActions>
            </React.Fragment>
          ) : (
            <div>Choose a card to edit</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
