import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Dropdown from "../dropdown";
import RemoveButton from "../removeButton";
import Input from "../input";
import DropdownActions from "../dropdownActions";

import styles from "./associations.module.css";
import CalendarWithInputMask from "../calendarWithInputMask";
import { useValidationError } from "../../../helpers/hooks";

const Associations = ({ selectedAssociations, submitChanges, validation, showRequiredText }) => {
  if (Object.keys(validation).length && !validation.enabled) {
    return null;
  }

  const { t } = useTranslation();
  const refButton = useRef();
  const isRequired = Object.keys(validation).length ? validation.required : true;
  const isNotRequired = !Object.keys(validation).length;
  const isRequiredText = validation.required && !selectedAssociations?.length && showRequiredText;
  const [mainErrors, setMainErrors] = useState({});
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    if (!selectedList.length) {
      setSelectedList(selectedAssociations);
    } else {
      const newAssociations = selectedList.map((item, index) => {
        return selectedAssociations[index] ? selectedAssociations[index] : item;
      });
      setSelectedList(newAssociations);
    }
  }, [selectedAssociations.length]);

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const onSubmit = list => {
    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = list
        .map(item => {
          const { isNew, ...rest } = item;
          return { ...rest };
        })
        .filter(item => Object.values(item).some(listItem => listItem));

      submitChanges({ associations: updatedList }, "associations", "save_association");
    }
  };

  const handleSubmit = (data, isNew, index) => {
    if (isNew) {
      const newPractices = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data, isNew };
        }

        return { ...item, isNew };
      });

      setSelectedList(newPractices);
      onSubmit(newPractices);
    } else {
      const newPractices = selectedList.map((item, currentIndex) => {
        if (String(currentIndex) === String(index)) {
          return { ...data };
        }

        return { ...item };
      });

      setSelectedList(newPractices);
      onSubmit(newPractices);
    }
  };

  const handleAddEmpty = () => {
    if (refButton?.current && selectedList.filter(item => item.isNew).length) {
      refButton.current.click();
    }

    if (!Object.values(mainErrors).filter(item => Boolean(item)).length) {
      const updatedList = selectedList.filter(item => Object.values(item).some(listItem => listItem));

      if (selectedList.length === updatedList.length) {
        setSelectedList([...selectedList, { association_joinyear: "", association_name: "", association_position: "", isNew: true }]);
      }
    }
  };

  const handleRemove = index => {
    const newSelectedList = selectedList.filter((item, currentIndex) => {
      return String(index) !== String(currentIndex);
    });

    setSelectedList(newSelectedList);
    onSubmit(newSelectedList);
  };

  const selectedLength = selectedAssociations.length;
  const title = `${validation?.custom_name || `Association${selectedLength > 1 ? "s" : ""}`}${
    selectedLength ? ` (${selectedLength})` : ""
  }`;

  return (
    <Dropdown
      description={validation.custom_description}
      isRequired={isRequired}
      title={title}
      isRequiredText={isRequiredText}
      handleMouseMove={handleMouseMove}
    >
      <div>
        <div>
          {selectedList.map((item, index) => {
            return (
              <AssociationsItem
                // eslint-disable-next-line react/no-array-index-key
                key={`joinyear-${item.association_joinyear}-index-${index}${item.association_name}-${item.association_position}-associations`}
                handleSubmit={handleSubmit}
                handleRemove={handleRemove}
                handleRemoveEmpty={handleRemove}
                selected={item}
                index={index}
                isNotRequired={isNotRequired}
                isRequired={isRequired}
                refButton={refButton}
                setIsEdited={setIsEdited}
                isEdited={isEdited}
                setMainErrors={setMainErrors}
                mainErrors={mainErrors}
              />
            );
          })}
        </div>
      </div>
      <DropdownActions buttonHandle={handleAddEmpty} buttonText={t("dashboard_single_listing_addassociation")} />
    </Dropdown>
  );
};

const AssociationsItem = ({
  selected,
  handleSubmit: onSubmit,
  index,
  handleRemove,
  handleRemoveEmpty,
  isNotRequired,
  isRequired,
  refButton,
  isEdited,
  setIsEdited,
  setMainErrors,
  mainErrors
}) => {
  const { t } = useTranslation();
  const { isNew } = selected;
  const [isMouseInBlock, setIsMouseInBlock] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const containerRef = useRef();

  const handleTouchStart = () => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const validate = values => {
    const { association_name, association_joinyear, association_position } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!association_name) {
      errors.association_name = requiredText;
    }
    if (!association_joinyear) {
      errors.association_joinyear = requiredText;
    }
    if (!association_position) {
      errors.association_position = requiredText;
    }

    const mainErrorsValue = mainErrors;
    mainErrorsValue[index] = Object.keys(errors).length ? errors : null;
    setMainErrors(mainErrorsValue);

    return errors;
  };

  const handleMouseMove = () => {
    if (!isMouseInBlock) {
      setIsMouseInBlock(true);
    }
  };

  const formik = useFormik({
    initialValues: { ...selected, isEdited: false },
    onSubmit: values => {
      // eslint-disable-next-line
      console.log("values.association_joinyear", values.association_joinyear);
      const normalizedData = {
        association_joinyear: values.association_joinyear,
        association_name: values.association_name,
        association_position: values.association_position,
        prkey: values.prkey
      };

      return onSubmit(normalizedData, isNew, index);
    },
    validate
  });

  const { values, setFieldValue, handleSubmit, errors } = formik;

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);

    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }
    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const onRemove = () => {
    if (selected.isNew) {
      handleRemoveEmpty(index);
      const mainErrorsValue = mainErrors;

      if (mainErrorsValue[index]) {
        mainErrorsValue[index] = null;
        setMainErrors(mainErrorsValue);
      }
    } else {
      handleRemove(index, index, true);
    }
  };

  const onHandleSubmit = () => {
    formik.setFieldTouched("association_name");
  };

  const handleChangeDate = date => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (isEdited) {
      setIsEdited(true);
    }

    formik.setFieldValue("association_joinyear", date);
  };

  const handleChangeInput = value => {
    if (!values.isEdited) {
      setFieldValue("isEdited", true);
    }

    if (!isEdited) {
      setIsEdited(true);
    }

    formik.setFieldValue("association_joinyear", value);
  };

  const handleMouseLeave = () => {
    if (isMouseInBlock) {
      setIsMouseInBlock(false);

      if (
        (values.association_joinyear || values.association_name || values.association_position) &&
        values.isEdited &&
        !Object.keys(errors).length
      ) {
        handleSubmit();
      }
      setIsEdited(false);
    }
  };

  useEffect(() => {
    const handleTouch = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (isTouched) {
          setIsTouched(false);

          if (
            (values.association_joinyear || values.association_name || values.association_position) &&
            values.isEdited &&
            !Object.keys(errors).length
          ) {
            setIsEdited(false);
            setFieldValue("isEdited", true);
            handleSubmit();
          }
        }
      }
    };

    document.addEventListener("touchstart", handleTouch, true);

    return () => {
      document.removeEventListener("touchstart", handleTouch, true);
    };
  }, [isEdited, values.association_joinyear, values.association_name, values.association_position]);

  useValidationError(Object.values(mainErrors).some(item => Boolean(item)) ? { associations: "Error" } : "isRemove", "associations");

  return (
    <div
      ref={containerRef}
      className={styles.association}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
    >
      <div className={styles.associationName}>
        <Input
          placeholder={t("dashboard_listings_edit_association_name_input")}
          error={errors.association_name}
          id="association_name"
          isInvalid={errors.association_name}
          name="association_name"
          onChange={handleChange}
          required={isRequired}
          value={values.association_name}
        />
      </div>

      <RemoveButton className={styles.removeButton} onClick={onRemove} />

      <div className={styles.flex}>
        <span className={styles.label}>{t("dashboard_listings_edit_association_name_position")}</span>
        <Input
          classNameWrap={styles.position}
          placeholder={t("dashboard_single_listing_input_position")}
          error={errors.association_position}
          id="association_position"
          isInvalid={errors.association_position}
          name="association_position"
          onChange={handleChange}
          required={isNotRequired}
          value={values.association_position}
        />

        <span className={styles.label}>{t("dashboard_listings_edit_association_name_year")}</span>

        <CalendarWithInputMask
          error={errors.association_joinyear}
          isInvalid={errors.association_joinyear}
          value={values.association_joinyear ? values.association_joinyear : null}
          onChange={date => handleChangeDate(date)}
          handleChangeInput={handleChangeInput}
          required={isNotRequired}
          placeholder="YYYY"
          handleSubmit={handleSubmit}
          defaultMaxDate={new Date()}
          isHideFeatureDate
          mask="9999"
          isYear
          minDate={new Date("1899")}
        />

        <button ref={refButton} type="button" className={styles.hidden} onClick={onHandleSubmit} />
      </div>
    </div>
  );
};

export default Associations;
