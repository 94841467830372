import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../media/icons/close-cross.svg";
import styles from "./publicHeader.module.css";
import { Hamburger } from "../header/hamburger";
import { actions as mainActions } from "../../actions/main";
import { ReactComponent as MainLogo } from "../../media/icons/mainLogo.svg";

const PublicHeader = () => {
  const dispatch = useDispatch();
  const { isActiveMobileMenu } = useSelector(state => state.main);

  const toggleMobileMenu = useCallback(isActive => dispatch(mainActions.toggleMobileMenu(isActive)), [dispatch]);

  const handleOpenNewListing = () => {
    toggleMobileMenu(!isActiveMobileMenu);
  };

  const renderNavigation = () => {
    return (
      <div className={styles.links}>
        {/*

        <NavLink to="/pricing" className={styles.link} activeClassName={styles.activeLink}>
          Pricing
        </NavLink>
        <NavLink to="/communities" className={styles.link} activeClassName={styles.activeLink}>
          Communities
        </NavLink>
        */}
        <Link to="/login" className={styles.login}>
          Login
        </Link>
      </div>
    );
  };

  return (
    <header className={styles.content}>
      <Link to="/" className={styles.logo}>
        <MainLogo className={styles.mainLogo} />
      </Link>

      {isActiveMobileMenu && <div className={styles.activeMenu}>{renderNavigation()}</div>}
      <div className={styles.activeMenuTablet}>{renderNavigation()}</div>

      <div className={styles.hamburger}>
        {isActiveMobileMenu ? (
          <button type="button" className={styles.closeIcon} onClick={handleOpenNewListing}>
            <CloseIcon />
          </button>
        ) : (
          <Hamburger toggleDropdown={handleOpenNewListing} activeMenu={isActiveMobileMenu} />
        )}
      </div>
    </header>
  );
};

export default PublicHeader;
