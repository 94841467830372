import queryString from "query-string";
import { questions } from "../constants/actionTypes";

const defaultState = {
  qa: [],
  savedQuestion: [],
  answers: [],
  practices: [],
  professions: [],
  error: null,
  preloader: false,
  currentQA: { answers: [], question: {} },
  updateQuestionAnswers: false,
  pagination: {},
  myAnswerPagination: {},
  myAnswersFilter: {}
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  let updatedQuestions = [];
  let updatedAnswers = [];
  let lastPageNum = 0;
  let currentPageNum = 0;
  let lastPageNumMyAnswer = 0;
  let currentPageNumMyAnswer = 0;

  switch (type) {
    case questions.GET_QA_SUCCESS:
      lastPageNum = parseInt(queryString.parse(queryString.extract(action.payload.pagination.last_page)).page, 10);
      currentPageNum = parseInt(queryString.parse(queryString.extract(action.payload.pagination.current_page)).page, 10);

      lastPageNumMyAnswer = parseInt(queryString.parse(queryString.extract(payload.myAnswerPagination.last_page)).page, 10);
      currentPageNumMyAnswer = parseInt(queryString.parse(queryString.extract(payload.myAnswerPagination.current_page)).page, 10);

      return {
        ...state,
        qa: payload.qa,
        answers: payload.myAnswers,
        savedQuestion: payload.savedQuestion,
        practices: payload.practices,
        categories: payload.categories,
        professions: payload.professions,
        pagination: { currentPageNum, lastPageNum, perPage: payload.pagination.per_page },
        preloader: false,
        myAnswersFilter: payload.myAnswersFilter,
        myAnswerPagination: { currentPageNumMyAnswer, lastPageNumMyAnswer, perPage: payload.myAnswerPagination.per_page }
      };

    case questions.GET_MY_ANSWER:
      lastPageNumMyAnswer = parseInt(queryString.parse(queryString.extract(payload.myAnswerPagination.last_page)).page, 10);
      currentPageNumMyAnswer = parseInt(queryString.parse(queryString.extract(payload.myAnswerPagination.current_page)).page, 10);

      return {
        ...state,
        answers: payload.myAnswers,
        myAnswersFilter: payload.myAnswersFilter,
        myAnswerPagination: { currentPageNumMyAnswer, lastPageNumMyAnswer, perPage: payload.myAnswerPagination.per_page },
        preloader: false
      };

    case questions.GET_ALL_MY_ANSWER:
      lastPageNumMyAnswer = parseInt(queryString.parse(queryString.extract(payload.myAnswerPagination.last_page)).page, 10);
      currentPageNumMyAnswer = parseInt(queryString.parse(queryString.extract(payload.myAnswerPagination.current_page)).page, 10);

      return {
        ...state,
        allQuestionAndAnswers: payload.myAnswers,
        allQuestionAndAnswersFilter: payload.myAnswersFilter,
        allQuestionAndAnswerPagination: { currentPageNumMyAnswer, lastPageNumMyAnswer, perPage: payload.myAnswerPagination.per_page },
        preloader: false
      };

    case questions.GET_MY_QUESTIONS:
      lastPageNum = parseInt(queryString.parse(queryString.extract(action.payload.pagination.last_page)).page, 10);
      currentPageNum = parseInt(queryString.parse(queryString.extract(action.payload.pagination.current_page)).page, 10);

      return {
        ...state,
        qa: payload.qa,
        pagination: { currentPageNum, lastPageNum, perPage: payload.pagination.per_page },
        preloader: false
      };

    case questions.GET_MY_ANSWER_FAIL:
      return {
        ...state,
        preloader: false
      };

    case questions.GET_MY_QUESTIONS_FAIL:
      return {
        ...state,
        preloader: false
      };

    case questions.CLEAN_QUESTIONS:
      return {
        ...state,
        qa: [],
        answers: [],
        savedQuestion: [],
        practices: [],
        categories: [],
        professions: []
      };

    case questions.CLEAN_CURRENT_QUESTIONS:
      return {
        ...state,
        currentQA: { answers: [], question: {} }
      };

    case questions.GET_CURRENT_QUESTION_ANSWER:
      return {
        ...state,
        currentQA: { ...payload }
      };

    case questions.GET_QA_FAIL:
      return {
        ...state,
        preloader: false,
        error: payload
      };

    case questions.SET_QA_PRELOADER:
      return {
        ...state,
        preloader: payload
      };

    case questions.QA_MARK_SAVED:
      updatedQuestions = state.qa.map(item => (item.question_id === payload ? { ...item, saved: 1 } : item));
      updatedAnswers = state.answers.map(item => (item.question_id === payload ? { ...item, saved: 1 } : item));
      return {
        ...state,
        preloader: false,
        qa: updatedQuestions,
        answers: updatedAnswers,
        savedQuestion: updatedQuestions.filter(item => item.saved)
      };

    case questions.QA_MARK_UNSAVED:
      updatedQuestions = state.qa.map(item => (item.question_id === payload ? { ...item, saved: 0 } : item));
      updatedAnswers = state.answers.map(item => (item.question_id === payload ? { ...item, saved: 0 } : item));
      return {
        ...state,
        preloader: false,
        qa: updatedQuestions,
        answers: updatedAnswers,
        savedQuestion: updatedQuestions.filter(item => item.saved)
      };

    case questions.QA_MARK_SAVED_SINGLE:
      updatedQuestions = { ...state.currentQA.question, saved: 1 };

      return {
        ...state,
        preloader: false,
        currentQA: { answers: state.currentQA.answers, question: updatedQuestions }
      };

    case questions.QA_MARK_UNSAVED_SINGLE:
      updatedQuestions = { ...state.currentQA.question, saved: 0 };

      return {
        ...state,
        preloader: false,
        currentQA: { answers: state.currentQA.answers, question: updatedQuestions }
      };

    case questions.QA_UPDATE_ANSWERS:
      return {
        ...state,
        updateQuestionAnswers: payload
      };

    default:
      return {
        ...state
      };
  }
}
