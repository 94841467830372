import axios from "axios";

import { teams } from "../constants/actionTypes";

export const actions = {
  setLoading(name, isLoading) {
    return {
      type: teams.SET_LOADING,
      payload: { name, isLoading }
    };
  },

  getTeams() {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      axios
        .all([
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/teams/owner"
          }),
          axios({
            method: "get",
            responseType: "json",
            url: "/members/json-api/teams/member"
          })
        ])
        .then(
          axios.spread((owner, member) => {
            dispatch({
              type: teams.GET_TEAMS,
              payload: {
                owner: owner.data,
                member: member.data
              }
            });
          })
        )
        .catch(error => {
          dispatch({
            type: teams.GET_TEAMS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },
  getTeamsMember() {
    return dispatch => {
      dispatch(this.setLoading("page", true));

      axios({
        method: "get",
        responseType: "json",
        url: "/members/json-api/teams/member"
      })
        .then(response => {
          dispatch({
            type: teams.GET_TEAMS_MEMBER,
            payload: response.data
          });
        })
        .catch(error => {
          dispatch({
            type: teams.GET_TEAMS_MEMBER_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },
  removeAccess(data) {
    return dispatch => {
      dispatch(this.setLoading("remove", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/teams/remove",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        data
      })
        .then(() => {
          dispatch({
            type: teams.REMOVE_ACCESS,
            payload: data.team_member_id
          });
        })
        .catch(error => {
          dispatch({
            type: teams.REMOVE_ACCESS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },
  rejectAccess(data) {
    return dispatch => {
      dispatch(this.setLoading("reject", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/teams/reject",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        data
      })
        .then(() => {
          dispatch({
            type: teams.REJECT_ACCESS,
            payload: data.team_member_id
          });
        })
        .catch(error => {
          dispatch({
            type: teams.REJECT_ACCESS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },
  acceptAccess(data) {
    return dispatch => {
      dispatch(this.setLoading("accept", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/teams/accept",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        data
      })
        .then(response => {
          dispatch({
            type: teams.ACCEPT_ACCESS,
            payload: response
          });

          dispatch(this.getTeamsMember());
        })
        .catch(error => {
          dispatch({
            type: teams.ACCEPT_ACCESS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },
  inviteAccess(data) {
    return dispatch => {
      dispatch(this.setLoading("invite", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/teams/invite",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        data
      })
        .then(response => {
          dispatch({
            type: teams.INVITE_ACCESS,
            payload: response
          });

          dispatch(this.getTeams());
        })
        .catch(error => {
          dispatch({
            type: teams.INVITE_ACCESS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  updateAccess(data) {
    return dispatch => {
      dispatch(this.setLoading("update", true));

      axios({
        method: "post",
        responseType: "json",
        url: "/members/json-api/teams/update",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        data
      })
        .then(response => {
          dispatch({
            type: teams.UPDATE_ACCESS,
            payload: response
          });

          setTimeout(() => {
            dispatch(this.getTeams());
          }, 3000);
        })
        .catch(error => {
          dispatch({
            type: teams.UPDATE_ACCESS_FAIL,
            payload: error.response.data.error
          });
        });
    };
  },

  toggleModal(modal, isActive) {
    return {
      type: teams.TOGGLE_MODAL,
      payload: {
        modal,
        isActive
      }
    };
  },

  handleActiveAccess(access) {
    return {
      type: teams.ACTIVE_ACCESS,
      payload: access
    };
  },

  clearAllModals() {
    return {
      type: teams.CLEAR_ALL_MODALS
    };
  },

  clearError() {
    return {
      type: teams.CLEAR_ERROR
    };
  },

  setCurrentTeam(team) {
    return {
      type: teams.SET_TEAM,
      payload: team
    };
  }
};
