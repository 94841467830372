import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import Input from "../../common/input";
import { Button } from "../../common/button";
import { ReactComponent as SearchIcon } from "../../../media/icons/landing/searchInput.svg";
import { ReactComponent as IconArrow } from "../../../media/icons/arrow-up.svg";
import CityStateZipInput from "../cityStateZipInput";
import styles from "./search.module.css";

const Search = ({ getCityStateZip, cityStateZipList, practices, activeDropdown }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [cityStateZipValue, setCityStateZipValue] = useState("");
  const [activeCityStateZip, setActiveCityStateZip] = useState({});
  const history = useHistory();
  const [activePracticeDropdown, setActivePracticeDropdown] = useState(false);
  const [activePractice, setActivePractice] = useState({});

  const toggleActiveDropdown = () => {
    setActivePracticeDropdown(prev => !prev);
  };

  const handleActivePractice = practice => () => {
    setActivePracticeDropdown(false);
    setActivePractice(practice);
  };

  const handleSubmit = () => {
    const state = activeCityStateZip.state_code ? { state_code: activeCityStateZip.state_code } : {};
    const city = activeCityStateZip.city ? { city: activeCityStateZip.state_code } : {};
    const postalCode = activeCityStateZip.postal_code ? { city: activeCityStateZip.postal_code } : {};
    const practiceId = activePractice.practice_id ? { practice_id: activePractice.practice_id } : {};
    const searchValue = search ? { search } : {};

    const queryData = {
      country_code: activeCityStateZip.country_code ? activeCityStateZip.country_code.toLowerCase() : "us",
      ...searchValue,
      ...state,
      ...city,
      ...postalCode,
      ...practiceId
    };

    const filterParams = queryString.stringify({ ...queryData }, { arrayFormat: "index" });

    history.push(`/search-result?${filterParams}`);
  };

  useEffect(() => {
    if (activeDropdown.profession_id !== activePractice.profession_id) {
      setActivePractice({});
    }
  }, [activeDropdown]);

  const practicesList = practices.map(item => (
    <React.Fragment key={item.practice_id}>
      <button type="button" className={styles.dropdownOptionsBold} onClick={handleActivePractice(item)}>
        {item.title}
      </button>
      {item.practices.map(practice => (
        <button type="button" className={styles.dropdownOptions} key={practice.practice_id} onClick={handleActivePractice(practice)}>
          {practice.title}
        </button>
      ))}
    </React.Fragment>
  ));

  return (
    <div className={styles.content}>
      <Input
        classNameWrap={styles.inputWrapper}
        className={styles.input}
        placeholder="Search by name, company or subject"
        icon={<SearchIcon className={styles.searchIcon} />}
        value={search}
        onChange={event => setSearch(event.target.value)}
      />
      <div className={styles.selectContainer}>
        <button type="button" className={styles.practicesDropdownButton} onClick={toggleActiveDropdown}>
          {activePractice.title || "Practice Area"}
          <IconArrow className={cn(styles.practiceArrow, activePracticeDropdown ? styles.practiceArrowActive : "")} />
        </button>

        {activePracticeDropdown && <div className={styles.practicesDropdownList}>{practicesList}</div>}
      </div>

      <CityStateZipInput
        cityStateZipList={cityStateZipList}
        setCityStateZipValue={setCityStateZipValue}
        cityStateZipValue={cityStateZipValue}
        getCityStateZip={getCityStateZip}
        setActiveCityStateZip={setActiveCityStateZip}
      />

      <Button type="submit" onClick={handleSubmit} className={styles.button}>
        {t("dashboard_hp_search_button")}
      </Button>
    </div>
  );
};

export default Search;
