import React from "react";

import ListingContent from "../listingContent";
import EventsListing from "../eventsListing";

const Events = ({ isExpand, onClick, isActive, handleClickAll, list, addToFavorite, removeFavorite, count }) => {
  return (
    <>
      {list?.length ? (
        <>
          {isExpand ? (
            <>
              {list?.length ? <EventsListing addToFavorite={addToFavorite} removeFavorite={removeFavorite} isExpand list={list} /> : null}
            </>
          ) : (
            <ListingContent title="Events" count={count || {}} onClick={onClick} isActive={isActive} handleClickAll={handleClickAll}>
              {list?.length ? <EventsListing removeFavorite={removeFavorite} addToFavorite={addToFavorite} list={list} /> : null}
            </ListingContent>
          )}
        </>
      ) : null}
    </>
  );
};

export default Events;
