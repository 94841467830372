import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Profession from "./profession";
import styles from "./searchResult.module.css";
import PublicHeader from "../publicHeader";
import Sidebar from "./sidebar";
// todo remove
// import Form from "./form";
import QuestionsAndAnswers from "./questionsAndAnswers";
import Articles from "./articles";
import Events from "./events";
import Jobs from "./jobs";
import Footer from "../footer";
import { OverlayPreloader } from "../common/preloader";
import { actions } from "../../actions/searchResult";
import { getQueryParams } from "../../helpers";

const SearchResult = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useMemo(() => getQueryParams(history.location.search), [history.location]);
  const getSearchResult = useCallback(data => dispatch(actions.getSearchResult(data)), [dispatch]);
  const { preloaders, articles, events, freeListings, jobs, featuredPractices, questions, sponsorListings } = useSelector(
    state => state.searchResult
  );

  useEffect(() => {
    if (Object.keys(queryParams).length) {
      getSearchResult(queryParams);
    }
  }, [queryParams.practice_id]);

  const renderFreeListings = freeListings.length
    ? freeListings.map(item => {
        return <Profession isFree key={item.listing_id} current={item} />;
      })
    : null;

  const renderSponsorListings = sponsorListings.length
    ? sponsorListings.map(item => {
        return <Profession key={item.listing_id} current={item} />;
      })
    : null;

  return (
    <>
      {preloaders.page && <OverlayPreloader />}

      <PublicHeader />

      <section className={styles.container}>
        <div className={styles.content}>
          <main className={styles.leftSide}>
            {/*
            <h1 className={styles.title}>Arab Immigration Lawyers in Texas</h1>
            <p className={styles.description}>
              Find an Arab lawyer in Texas. We check the licenses of all attorneys listed on our website once a year to make sure they are
              in good standing with the state agency issuing their license.
            </p>
            */}
            {renderSponsorListings}
            {/*
            <Form />
            */}
            {renderFreeListings}
            {questions.length ? <QuestionsAndAnswers questions={questions} /> : null}
            {articles.length ? <Articles articles={articles} /> : null}
            {events.length ? <Events events={events} /> : null}
            {jobs.length ? <Jobs jobs={jobs} /> : null}
          </main>
          <Sidebar featuredPractices={featuredPractices} />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SearchResult;
