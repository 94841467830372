import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import main from "./main";
import auth from "./auth";
import home from "./home";
import signUp from "./signUp";
import inbox from "./inbox";
import listing from "./listing";
import account from "./account";
import payment from "./payment";
import payPerLead from "./payPerLead";
import callHistory from "./callHistory";
import payPerLeadDetail from "./payPerLeadDetail";
import performance from "./performance";
import referralRequests from "./referralRequests";
import referralRequestDetail from "./referralRequestDetail";
import questions from "./questions";
import messages from "./messages";
import teams from "./teams";
import searchResult from "./searchResult";
import landing from "./landing";
import publicListing from "./publicListing";

const enhancers = applyMiddleware(thunk);

const rootReducer = combineReducers({
  account,
  auth,
  callHistory,
  referralRequests,
  referralRequestDetail,
  home,
  inbox,
  listing,
  main,
  payment,
  payPerLead,
  payPerLeadDetail,
  performance,
  questions,
  signUp,
  messages,
  teams,
  searchResult,
  landing,
  publicListing
});

export default createStore(rootReducer, composeWithDevTools(enhancers));
