import React from "react";
import cn from "classnames";

import styles from "./directories.module.css";

export const Directories = ({ professions, activeProfessional, handleChangeProfessional }) => {
  const directoriesList = professions.map(item => {
    return (
      <Directory
        title={item.title}
        key={item.title}
        isActive={activeProfessional.profession_id === item.profession_id}
        onClick={() => handleChangeProfessional(item)}
        isHide={item.title.includes("Organizations") && item.title !== "Organizations" && item.title !== "Businesses / Organizations"}
      />
    );
  });

  return <div>{directoriesList}</div>;
};

const Directory = ({ title, isActive, onClick, isHide }) => {
  const activeProfessional = isActive ? styles.directoryActive : "";
  const hideProfessional = isHide ? styles.directoryHide : "";

  return (
    <button type="button" className={cn(styles.directory, activeProfessional, hideProfessional)} onClick={onClick}>
      + {title} Listing
    </button>
  );
};
